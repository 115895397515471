import { useEffect } from "react";
import "./Growth.scss";

export default function Growth() {

  // useEffect(() => {
  //   alert("growth");
  // }, []);

  return (
    <div className="growth_container">
        <h1>Coming Soon..</h1>
    </div>
  );
}
